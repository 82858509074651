import * as React from "react"


export interface OptionsProps {
    id: Number
    Titre : String 
    Information : String 
}

const Option:React.FC<OptionsProps> = (option) => {
    return ( <a href={"#option_" + option.id}> {option.Titre}</a>)
}

export default Option;