import * as React from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Option, { OptionsProps } from './Option';

export interface TarifProps {
    id: number
    Titre: String
    SousTitre: String
    Couverture: {
        localFile: {
            childImageSharp: {
                gatsbyImageData: IGatsbyImageData
            }
        }
    }
    Introduction: String
    Options: OptionsProps[]
    Conclusion: String
    Prix: Number
}

const Tarif: React.FC<TarifProps> = (tarif) => {
    return (
        <div className="column is-3 pareo-tarif" key={tarif.id}>
            <div>
                <p className="title">
                    {tarif.Titre}
                </p>
                <p className="subtitle">
                    {tarif.SousTitre}
                </p>
                <img className="" src={tarif.Couverture.localFile.childImageSharp.gatsbyImageData.images.fallback?.src} alt={"Couvertur tarif " + tarif.Titre} />
                <div className="">{tarif.Introduction} </div>
                <table className="table container">
                    <tbody>
                        {tarif.Options.map(option => (
                            <tr ><td> {Option(option)}</td></tr>
                        ))}
                    </tbody>
                </table>
                <div className="">{tarif.Conclusion} </div>
            </div>
            <div className="pareo-prix">
                <h3>{tarif.Prix} €</h3>
            </div>
        </div>
    )
}


export default Tarif;