import React from "react"

import { graphql, Link } from "gatsby"
import IndexLayout from '../layouts'
import GroupeTarifaire, { GroupeTarifaireProps } from '../components/GroupeTarifaire'

const PlaquettePage = ({ data }) => {
  const plaquette = data.strapiPlaquette
  return (
    <IndexLayout>
      <div className="hero is-primary">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title ">{plaquette.Titre}</h1>
            <h2 className="subtitle">{plaquette.SousTitre}</h2>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container has-text-centered ">{plaquette.Introduction}</div>
        <div>
          <div>
            {
              plaquette.Groupes.map((groupeTarifaire) => {
                return (
                  <>
                    {GroupeTarifaire(groupeTarifaire)}
                    <hr />
                  </>
                )
              }
              )}
          </div>
        </div>
        <div className="container has-text-centered">{plaquette.Conclusion}</div>
      </div>
    </IndexLayout>
  )
}

export default PlaquettePage

export const pageQuery = graphql`
query PlaquetteQuery {
  strapiPlaquette {
    Titre
    SousTitre
    Introduction
    Groupes {
      Titre
      SousTitre
      Introduction
      Tarifs {
        Titre
        SousTitre
        Couverture {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 800 
                aspectRatio: 1.5
                transformOptions: {fit: COVER, cropFocus: CENTER}
                placeholder: BLURRED
              )
            }
          }
        }
        Introduction
        Options {
          Titre
          Information
        }
        Conclusion
        Prix
      }
      Conclusion
    }
    Conclusion
  }
}
`
