import * as React from 'react'
import Tarif, { TarifProps } from './Tarif'

interface GroupeTarifaireProps {
  id: number
  Titre: String
  SousTitre: String
  Introduction: String
  Conclusion: String
  Tarifs: TarifProps[]
}

const GroupeTarifaire: React.FC<GroupeTarifaireProps> = groupeTarifaire => (
  <div className="has-text-centered pareo-groupe-tarifs " key={groupeTarifaire.id}>
    <div className="hero is-small is-primary">
      <div className="hero-body  ">
        <p className="title">
          {groupeTarifaire.Titre}
        </p>
        <p className="subtitle">
          {groupeTarifaire.SousTitre}
        </p>
      </div>
    </div>
    <div className="pareo-intro content">
      <div className="container has-text-centered ">
        {groupeTarifaire.Introduction}
      </div>
    </div>
    <div className="columns is-multiline ">
      {groupeTarifaire.Tarifs.map(tarif => (
        <>
          {Tarif(tarif)}
          <hr />
        </>
      ))
      }
    </div>
    <div className="pareo-outro content">
      <div className="container has-text-centered ">
        {groupeTarifaire.Conclusion}
      </div>
    </div>
  </div>
)

export default GroupeTarifaire
